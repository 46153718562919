import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HelpPage = ({ data }) => {

  return (

    <Layout>
      <SEO title="Ajuda" lang='pt-br' />

      <h2 className="mb-8 bold text-center sm:text-left sm:text-xl">Ajuda</h2>

      <div className="max-w-4xl">

      <p className="mt-4 text-justify">
          A taxa de rendimento dos títulos do Tesouro Direto normalmente são atualizadas de segunda a sexta-feira, três vezes ao dia, próximo dos horários: 9h30, 12h e 15h30.
        </p>

        <p className="mt-4 text-justify">
          Este site tem a finalidade de facilitar o acompanhamento dessa variação da taxa de rentabilidade a cada atualização assim como permitir a visualização do seu histórico através dos gráficos.
        </p>

        <p className="mt-4 text-justify">
          Os dados são extraídos do site do Tesouro Direto, processados e disponibilizados neste site.
        </p>

        <h2 className="mt-12 bold text-center sm:text-left sm:text-xl">Contato</h2>

        <p className="mt-8">
          Para enviar sugestões ou comunicar qualquer incorreção por favor entre em contato conosco através do e-mail <a className="underline" href="mailto:contato@taxa-tesouro.com">contato@taxa-tesouro.com</a>
        </p>

      </div>


    </Layout>
  )
};

export default HelpPage;
